<template>
  <details 
    class="nft-triggers"
    open>
    <summary class="nft-triggers__summary">{{ $t('user-portal.nft_triggers') }}</summary>

    <div class="nft-triggers__body">
      <ul class="nft-triggers__list">
        <card-trigger
          v-for="trigger in triggers"
          :trigger="trigger"
          :key="trigger.id"
        />
      </ul>
    </div>
  </details>
</template>

<script>
import CardTrigger from '../ui/card-trigger.vue'

export default {
  name: 'NftTriggers',

  components: {
    CardTrigger
  },

  props: {
    triggers: {}
  },
}
</script>