<template>
  <li class="user-nft-trigger">
    <data-tag :size="$pepper.Size.S">
      {{ availabilityLabel }}
    </data-tag>
    <p class="user-nft-trigger__name">{{ trigger.name }}</p>
    <div class="user-nft-trigger__message" v-html="message"></div>
    <div class="user-nft-trigger__secret" v-if="secret" v-html="secret"></div>
    <div class="user-nft-trigger__video-btn">
      <actions-button
        :size="$pepper.Size.S"
        v-if="isVideoStreaming"
        @click="() => viewVideo()"
      >{{ $t('user-portal.view_video') }}</actions-button>
    </div>
  </li>
</template>

<script>
export default {
  name: 'NftTrigger',

  props: {
    trigger: {
      type: Object
    }
  },

  computed: {
    isVideoStreaming() {
      return this.$basil.get(this.trigger, 'perk.grant.type') === 'video_access' &&
        this.$basil.get(this.trigger, 'perk.grant.subtype') === 'streaming' &&
        !this.$basil.isNil(this.$basil.get(this.trigger, 'perk.grant.video_id'))
    },

    message() {
      return this.$basil.get(this.trigger, 'perk.client_message', '').replace(/(\r\n|\n\r|\r|\n)/g, '<br>')
    },

    secret() {
      return this.$basil.get(this.trigger, 'perk.wallet_message', '').replace(/(\r\n|\n\r|\r|\n)/g, '<br>')
    },

    availabilityLabel() {
      if (this.trigger.from === null && this.trigger.to === null) {
        return this.$t('user-portal.nft_trigger_available')
      }

      if (this.trigger.from !== null && this.trigger.to !== null) {
        const from = this.$basil.i18n.date(new Date(this.trigger.from))
        const to = this.$basil.i18n.date(new Date(this.trigger.to))

        return this.$t('user-portal.nft_trigger_available_from_to', { from, to })
      }

      if (this.trigger.from !== null) {
        const from = this.$basil.i18n.date(new Date(this.trigger.from))
        
        return this.$t('user-portal.nft_trigger_available_from', { from, to })
      }

      if (this.trigger.to !== null) {
        const to = this.$basil.i18n.date(new Date(this.trigger.to))
        
        return this.$t('user-portal.nft_trigger_available_to', { from, to })
      }
    }
  },

  methods: {
    viewVideo() {
      const triggerId = this.$basil.get(this.trigger, 'id')
      this.$router.push({ name: 'sayl-user-portal.stream.video', params: { triggerId } })
    }
  }
}
</script>